import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide,nextTick} from 'vue';
const WayBillCarCardHelper = defineComponent({
    name:'WayBillCarCard',
    title:'运单车次管理',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('gridTableOwner', proxy);
        provide('EditTableOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            fleetData:[],
            gridParam: {
                title:'订单信息',
                canPage:false,
                isShowIndexCol:false,
                queryParam: {
                    from:'wayBillCard',
                    wayBillId:'',//在befeoreOpen那里再去发一次请求,那个时候为其赋值
                },
                modelMethod: "/wayBill/pageData"
            },
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: true,
                details:['customerUnloadAddress'],
                modelPath: "/wayBillCar"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                pointNum: [
                    {required: true, message: "请选择配送点位数", trigger: "blur" }
                ],
                mileage: [
                    {required: true, message: "请选择里程数", trigger: "blur" }
                ],
                goodsName: [
                    {required: true, message: "请输入货物名称", trigger: "blur" }
                ],
                capacityPercent: [
                    {required: true, message: "请选择装载量", trigger: "blur" }
                ],
            }
        })
        onMounted(async ()=>{
            await buildFleetData();
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'driverId'==params.comboId){
                     return [{value:'0',label:'否'},{value:'1',label:'是'}]
                }
                if(params && 'carId'==params.comboId){
                     return [{value:'0',label:'否'},{value:'1',label:'是'}]
                }
                if(params && 'businessFleetId'==params.comboId){
                    return dataObj.fleetData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue,selectId)=>{
            if('driverId'==selectId){}
            if('carId'==selectId){}
        }
        //---------------------------computed---------------------------
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType) => {
                return {
                    detailParam: {
                        title:'卸货地址维护',
                        readOnly:dataObj.disabled,
                        canPage: false,
                        toolBar: [
                            {
                                id: "selfAdd",
                                title: "增加",
                                type: "primary",
                                icon: "el-icon-zoom-in",
                                clickEvent: 'addUnloadAddress',
                                param: ''
                            }
                        ],
                        canAdd: false,//关闭明细表格自带的新增按钮，因为添加了自己的toolBar，在toolBar中有自己的新增按钮
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: "/wayBillCar/detail?t="+Math.random()*1000
                    }
                }
            }
        })
        const addUnloadAddress=async ()=>{
            await utils.$$lghdUtils.buildChooseAddressDialog({from:'chooseUnloadAddress',proxy:proxy,customerId:dataObj.form.customerId,wayBillCarId:dataObj.form.id});
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            if(data.data.status==5)dataObj.disabled=true;
            dataObj.gridParam.queryParam.wayBillId=data.data.wayBillId;
            // nextTick(async ()=>{
            //     await dataObj.refMap.get('gridRef1').reloadGrid();
            // })
        }
        const afterOpened=async(data,addOrLoad,cardEngine)=>{
            await dataObj.refMap.get('gridRef1').reloadGrid();
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            let customerUnloadAddress=dataObj.refMap.get('customerUnloadAddress').getDetailData();
            if(customerUnloadAddress.length==0){
                utils.$$tools.warning({message:'卸货地址明细不能为空'});
                return false;
            }
            let flagTrueNum=0;
            for(let i=0;i<customerUnloadAddress.length;i++){
                if(customerUnloadAddress[i]['F_FLAG']){
                    flagTrueNum+=1;
                    dataObj.form.customerAddressId=customerUnloadAddress[i]['F_CUSTOMER_ADDRESS_ID'];
                }
            }
            if(flagTrueNum!=1){
                utils.$$tools.warning({message:'卸货地址明细必须要有一个且只能有一个地址参与起步价计算'});
                return false;
            }
            // console.log(customerUnloadAddress);
            // return false;
            dataObj.form.pointNum=customerUnloadAddress.length;
            if(dataObj.form.mileage==0 || dataObj.form.capacityPercent==0){
                proxy.$message({showClose: true, message: '请填写里程数、装载量。', type: 'info'});
                return false;
            }
            return true;
        }
        //地图弹出框回调事件，在这可以拿到弹出框的实例，里面有选择的地址坐标等信息
        const sureHandler=async (modelInst)=>{
            let selectRows = modelInst.pageListRef.getTbInst().getSelection();//F_COORDINATE坐标
            if(selectRows.length>0){
                if('chooseUnloadAddress'==modelInst.engineParams.from){
                    for(let i=0;i<selectRows.length;i++){
                        let tempRow=Object.assign({},selectRows[i]);
                        tempRow['F_CUSTOMER_ADDRESS_ID']=selectRows[i]['F_ID'];
                        tempRow['F_FLAG']=false;
                        delete tempRow['F_ID'];
                        dataObj.refMap.get('customerUnloadAddress').addRow(tempRow);
                    }
                }
                let customerUnloadAddress=dataObj.refMap.get('customerUnloadAddress').getDetailData();
                dataObj.form.pointNum=customerUnloadAddress.length;
            }
            return true;
        }
        //体重重要输入框改变事件
        const inputNumChange=(newValue,name)=>{
            let sizeC=0,weightC=0;
            if(dataObj.form.size!=0)sizeC=dataObj.form.size*100/dataObj.form.cxSize;
            if(dataObj.form.weight!=0)weightC=dataObj.form.weight*100/dataObj.form.cxCapacity;
            if(sizeC>weightC)dataObj.form.capacityPercent=sizeC.toFixed(2);
            else dataObj.form.capacityPercent=weightC.toFixed(2);
            // if(dataObj.form.size>dataObj.form.weight){
            //     dataObj.form.capacityPercent=dataObj.form.size*100/dataObj.form.cxSize;
            // }else{
            //     dataObj.form.capacityPercent=dataObj.form.size*100/dataObj.form.cxCapacity;
            // }
            // dataObj.form.capacityPercent=dataObj.form.capacityPercent.toFixed(2);
        }
        const afterDelHandler=(detailType, row, index)=>{
            dataObj.form.pointNum=dataObj.form.pointNum-1;
        }
        const buildFleetData=async (cysId)=>{
            dataObj.fleetData= await utils.$$api.getFleetsByCys({cysId:cysId});
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,addUnloadAddress,beforeOpen,afterOpened,beforeSaveHandler,sureHandler,inputNumChange
            ,cardDetailParam,afterDelHandler,buildFleetData
        }
    }
});
export default WayBillCarCardHelper;