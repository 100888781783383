<template>
    <div class="WayBillCar">
        <GridTable :ref="el=>refMap.set('gridRef1',el)" v-bind="gridParam">
            <template v-slot:_tbCols>
                <el-table-column prop="F_CUSTOMER_ID" label="客户"/>
                <el-table-column prop="F_SALE_BILL_NAME" label="项目"/>
                <el-table-column prop="F_SALE_TYPE" label="销售类型"/>
                <el-table-column prop="F_LOAD_TIME" label="要求装货时间"/>
                <el-table-column prop="F_DELIVERY_TIME" label="要求送货时间"/>
                <el-table-column prop="F_CUSTOMER_ADDRESS_ID" label="装货点"/>
<!--                <el-table-column prop="F_POINT_NUM" label="配送点位数"/>-->
<!--                <el-table-column prop="F_MILEAGE" label="里程数"/>-->
                <el-table-column prop="F_TYPE" label="需求类型"/>
                <el-table-column prop="F_AUTH_CODE" label="委托单号"/>
                <el-table-column prop="F_COLLECTION_MONEY" label="代收货款"/>
<!--                <el-table-column prop="F_STATUS" label="运单状态"/>-->
                <el-table-column prop="F_TIME" label="创建时间"/>
            </template>
        </GridTable>
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px" style="margin-top: 10px;">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="司机" prop="driverId">
                        <el-input v-model="form.driverName"  :disabled="true"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系电话" prop="contact">
                        <el-input v-model="form.contact" :disabled="true"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车牌" prop="carNum">
                        <el-input v-model="form.carNum" :disabled="true"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="车型" prop="cx">
                        <el-input v-model="form.cx" :disabled="true"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="装货点" prop="loadPlaceInfo">
                        <el-input v-model="form.loadPlaceInfo" :disabled="true"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="业务车队" prop="businessFleetId">
                        <el-select :ref="el=>refMap.set('businessFleetId',el)" v-model="form.businessFleetId" clearable placeholder="请选择业务车队" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'businessFleetId')}">
                            <el-option v-for="item in comboSelect({comboId:'businessFleetId'})" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
                                {{ item.label.length>15?item.label.substr(0,15)+'...':item.label }}&nbsp;&nbsp;{{ item.yqm }}
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="货物名称" prop="goodsName">
                        <el-input @input="e => form.goodsName = validForbid(e)" v-model="form.goodsName" placeholder="请输入货物名称" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="件数" prop="num">
                        <div style="display:flex;align-items: center;">
                            <div style="flex: 1"><el-input-number v-model="form.num" :min="0" :max="9999999" precision=0 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                            <div class="unit">件</div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="配送点位数" prop="pointNum">
                        <div style="display:flex;align-items: center;">
<!--                            <div style="flex: 1"><el-input-number v-model="form.pointNum" :min="0" :max="9999999" precision=0 :disabled="disabled" style="width: 100%" controls-position="right"/></div>-->
                            <div style="flex: 1"><el-input-number v-model="form.pointNum" :min="0" :max="9999999" precision=0 :disabled="true" style="width: 100%" controls-position="right"/></div>
                            <div class="unit">个</div>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="里程数" prop="mileage">
                        <div style="display:flex;align-items: center;">
                            <div style="flex: 1"><el-input-number v-model="form.mileage" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                            <div class="unit">km</div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="体积" prop="size">
                        <div style="display:flex;align-items: center;">
                            <div style="flex: 1"><el-input-number v-model="form.size" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" @change="(val)=>{inputNumChange(val,'size')}" controls-position="right"/></div>
                            <div class="unit">立方</div>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="重量" prop="weight">
                        <div style="display:flex;align-items: center;">
                            <div style="flex: 1"><el-input-number v-model="form.weight" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" @change="(val)=>{inputNumChange(val,'weight')}" controls-position="right"/></div>
                            <div class="unit">吨</div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="代收货款" prop="collectionMoney">
                        <div style="display:flex;align-items: center;">
                            <div style="flex: 1"><el-input-number v-model="form.collectionMoney" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                            <div class="unit">元</div>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="装载量" prop="capacityPercent">
                        <div style="display:flex;align-items: center;">
                            <div style="flex: 1"><el-input-number v-model="form.capacityPercent" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                            <div class="unit">%</div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input @input="e => form.remark = validForbid(e)" v-model="form.remark" type="textarea" placeholder="请输入备注" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <EditTable :ref="el=>refMap.set('customerUnloadAddress',el)" v-bind="cardDetailParam('customerUnloadAddress')">
            <template v-slot:tbCols>
                <el-table-column prop="F_CONTACTS" label="联系人"/>
                <el-table-column prop="F_TELEPHONE" label="联系电话"/>
                <el-table-column prop="F_ADDRESS" label="联系地址"/>
                <el-table-column prop="F_AREA_NAME" label="所属区域"/>
<!--                <el-table-column prop="F_TIME" label="创建时间"/>-->
<!--                <el-table-column prop="F_COORDINATE" label="地址坐标"/>-->
                <el-table-column label="是否起步价计算区域" prop="F_AREA_ID">
                    <template #default="scope">
                        <span v-if="!scope.row.EDIT">{{ scope.row.F_FLAG==true?'是':'否' }}</span>
                        <el-switch v-model="scope.row.F_FLAG" v-else />
                    </template>
                </el-table-column>
            </template>
        </EditTable>
    </div>
</template>


<script>
    import WayBillCarCardHelper from "./WayBillCarCardHelper.js";
    export default WayBillCarCardHelper;
</script>

<style scoped>
    .WayBillCar{
        width: 100%;
    }
    .unit{
        width: 30px;font-size: 13px;padding-left: 5px;
    }
</style>
